<template>
    <!--
    Компонент строки поиска
    -->

    <el-input class="dom-search" v-model="valueInternal" :placeholder="placeholder" :size="size">

        <i v-if="iconLeft" slot="prefix" :class="iconLeft" class="icon-left"/>
        <i
            class="el-icon-search el-input__icon"
            slot="suffix"
        >
        </i>
    </el-input>


</template>

<script>

import ValueInternal from '@/views/mixins/ValueInternal'

export default {

    mixins: [ValueInternal],

    props: {
        placeholder: {
            type: String
        },
        size: {
            type: String
        },
        iconLeft: {
            type: String
        }
    },
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-search {

    ::v-deep input {
        background: $dom-light-gray;
        border-radius: 30px;
    }
    ::v-deep .el-input__suffix {
        background: $dom-gray;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        right: 0;
        top: -2px;
        color: $dom-white;
    }
    .icon-left {
        color: $dom-light-blue;
        margin-top: 13px;
        margin-left: 5px;
    }


}


</style>
