<template>
    <!--
    Компонент выбора шаблона голосования
    -->

  <el-container class="dom-dropdown-complex" >

    <el-dropdown class="dom-dropdown" >
      <dom-button style="justify-self: right">
        Шаблоны
        <i class="fas el-icon-fa-chevron-down "></i>
      </dom-button>

      <el-dropdown-menu slot="dropdown" >
        <el-dropdown-item v-for="item in items">
          {{ item['text'] }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <dom-button style="justify-self: right">
      Свой вопрос
    </dom-button>

  </el-container>


</template>

<script>
import DomButton from "@/views/elements/DomButton";

export default {
  name: "dom-dropdown-complex",
  components: {DomButton},
  props: {
  },

  data: () => ({
    items: [
      {id: 1, text: 'Свой вопрос'},
      {id: 2, text: 'Свой вопрос'},
      {id: 3, text: 'Свой вопрос'},
      {id: 4, text: 'Свой вопрос'},
    ],
  }),
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-dropdown-complex {
  background-color: $dom-white;
  width: 100%;

  justify-content: flex-end;
  padding: 10px;
  display: flex;
}

.dom-dropdown-content {
  justify-content: flex-end;
  padding: 10px;
  display: flex;
}

</style>