<template>
    <!--
    Диалог добавления нового объекта недвижимости
    -->
    <el-dialog
        width="75%"
        class="add-realty-dialog"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :show-close="false"
        :modal-append-to-body="false">

        <template v-slot:title>
            <dom-title-closable value="Добавление нового объекта недвижимости" close-text="Завершить без сохранения" @close="onCancel"/>
        </template>

        <el-card class="realty-dialog-wrapper">
            <el-form :model="data" :rules="rules" ref="form">
            <el-row class="realty-dialog__raw">
                <dom-dropdown left-icon="fas el-icon-fa-building" label="Тип недвижимости" v-model="data.realtyType" :items="realtyTypeItems" text-key="realtyType" value-key="realtyType"/>
            </el-row>
            <el-row class="realty-dialog__raw">
                <dom-dropdown left-icon="fas el-icon-fa-map" label="Регион РФ" v-model="data.region" :items="regions" text-key="regionNm" value-key="regionId"/>
            </el-row>
            <el-row class="realty-dialog__raw">
                <dom-input icon-left="fas el-icon-fa-map-marker-alt" placeholder="Адрес объекта недвижимости" v-model="data.address"/>
            </el-row>
            <el-row class="realty-dialog__raw">
                <dom-input v-model="data.cadNumber" placeholder="Кадастровый номер" icon-left="fas el-icon-fa-map-marked-alt"/>
            </el-row>
            <el-row style="margin: 10px 10px" :gutter="10">
                <el-col :span="5" style="text-align: left; line-height: 50px"><span>Количество квартир в доме</span></el-col>
                <el-col :span="3">
                    <dom-input v-model="data.flatsCount" type="number" placeholder="0000"/>
                </el-col>
            </el-row>
            <el-row style="margin: 10px 10px" :gutter="10">
                <el-col :span="4" style="text-align: left; line-height: 50px"><span>Общая площадь, м2</span></el-col>
                <el-col :span="3">
                    <dom-input v-model="data.areaTotal" type="number" placeholder="0000"/>
                </el-col>
            </el-row>
            <el-row style="margin: 10px 10px" :gutter="10">
                <el-col :span="7" style="text-align: left; line-height: 50px"><span>Общая площадь жилых помещений, м2</span></el-col>
                <el-col :span="3">
                    <dom-input v-model="data.areaLiving" type="number" placeholder="0000"/>
                </el-col>
            </el-row>
            <el-row style="margin: 10px 10px" :gutter="10">
                <el-col :span="8" style="text-align: left; line-height: 50px"><span>Общая площадь нежилых помещений, м2</span></el-col>
                <el-col :span="3">
                    <dom-input v-model="data.areaNonProperty" type="number" placeholder="0000"/>
                </el-col>
            </el-row>
            <el-row style="margin: 10px 10px" :gutter="10">
                <el-col :span="8" style="text-align: left; line-height: 50px"><span>Продолжительность собрания, от 7 до 60 дней</span></el-col>
                <el-col :span="3">
                    <el-form-item prop="votingDuration">
                        <dom-input v-model.number="data.votingDuration" type="number" placeholder="00"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <dom-button @click="onFinish"
                        style="margin-top: 20px; margin-left: 10px; height: 55px"
                        left-icon="fas el-icon-fa-save"
                        :disabled="!formPropertiesCompleted"
                        label="Сохранить"
            />
            </el-form>
        </el-card>


    </el-dialog>

</template>

<script>

import Toggleable from "@/views/mixins/Toggleable";
import DomDropdown from "@/views/elements/DomDropdown";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import DomInput from "@/views/elements/DomInput";
import {mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import _ from "lodash";
import api from '@/api'

export default {
    components: {
        DomButtonAccept,
        DomTitleClosable,
        DomInput,
        DomTextarea,
        DomButton,
        DomDropdown,
    },
    mixins: [Toggleable],

    data: () => ({
        properties: [],
        realtyTypeItems: [{realtyType: 'Многоквартирный дом'}],
        addressItems: [],

        realtyType: null,

        valid: false,
        data: {
            region: null,
            address: null,
            cadNumber: null,
            flatsCount: null,
            areaTotal: null,
            areaLiving: null,
            areaNonProperty: null,
            votingDuration: null,
        },

        rules: {
            votingDuration: [
                { type: 'number', min: 7, max: 60, message: 'Введите число от 7 до 60', trigger: 'blur' }
            ],
        },
    }),


    computed: {
        ...mapFields("user", ["me"]),

        formPropertiesCompleted() {
            return this.data.realtyType &&
                this.data.region &&
                this.data.address &&
                this.data.cadNumber &&
                this.data.flatsCount &&
                this.data.areaTotal &&
                this.data.areaLiving &&
                this.data.areaNonProperty &&
                this.data.votingDuration &&
                this.valid
        }

    },

    asyncComputed: {
        roles() {
            return this.getRoles()
        },
        regions: {
            get() {
                return this.getRegions().then(res => _.orderBy(res, 'regionNm'))
            },
            default: []
        },
    },

    watch: {
        data: {
            handler() {
                this.$refs['form'].validate(valid => this.valid = valid);
            },
            deep: true
        }
    },

    methods: {
        ...mapActions("property", {getAllProperties: "getAll"}),
        ...mapActions("users", {addUser: "add"}),
        ...mapActions("meta", ["getRoles", "getRegions"]),

        onCancel() {
            this.isActive = false;
        },

        onFinish() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.saveData();
                } else {
                    return false;
                }
            });

        },

        saveData() {

            const data = {
                realtyType: this.data.realtyType,
                region: this.data.region,
                address: this.data.address,
                cadNumber: this.data.cadNumber,
                flatsCount: this.data.flatsCount,
                areaTotal: this.data.areaTotal,
                areaLiving: this.data.areaLiving,
                areaNonProperty: this.data.areaNonProperty,
                votingDuration: this.data.votingDuration
            };

            api.property.addNewRealty(data).then(() => {
                this.getAllProperties();
                this.$message.success(`Недвижимость успешно сохранена.`);
                this.isActive = false;
            })
        },

    },
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.add-realty-dialog {
    ::v-deep .el-dialog {
        max-height: 80vh;
        max-width: 80vw;
        min-width: 800px;
        height: fit-content;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }

    .realty-dialog-wrapper {
        background-color: $dom-light-gray;

        .realty-dialog__raw {
            margin: 10px;
        }
    }
}
</style>
