<template>
    <!--
    Главная страница: сайдбар + главное меню + подстраница на основе маршрута
    -->

    <el-container class="wrapper2">

        <el-header height="70px">
            <root-header/>
        </el-header>

        <el-container>
            <el-aside :class="{collapsed_mobile: !isMenuOpenedGetter}" v-if="$isMobile()" class="full_aside_width">
                <root-sidebar @new-vote="onNewVote"/>
            </el-aside>

            <el-aside :class="{collapsed: !isMenuOpenedGetter}" v-else class="mobile_aside_width">
                <root-sidebar @new-vote="onNewVote"/>
            </el-aside>

            <el-main>
                <router-view/>

                <new-vote-dialog v-model="newVoteDialog"/>

            </el-main>

        </el-container>

    </el-container>

</template>

<script>


import {mapActions, mapGetters} from "vuex";
import RootHeader from "@/views/components/RootHeader";
import RootSidebar from "@/views/components/RootSidebar";
import NewVoteDialog from "@/views/dialogs/NewVoteDialog";
import VueScreenSize from "vue-screen-size";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],

    components: {NewVoteDialog, RootSidebar, RootHeader},

    data: () => ({
        newVoteDialog: false,
    }),

    watch: {
        newVoteDialog(newVoteDialog) {
            if (newVoteDialog === false)
                this.update()
        }
    },

    computed: {
        ...mapGetters("menu", ["isMenuOpenedGetter"]),
    },

    mounted() {
        this.update()
    },

    methods: {
        ...mapActions("login", ["clear_code"]),
        ...mapActions("voting", ["update"]),

        logout() {
            this.clear_code().then(() => location.reload())
        },

        onNewVote() {
            if (this.$isMobile()) this.$store.commit('menu/setMenu')

            this.newVoteDialog = true;
        },
    },
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.wrapper2 {
    min-height: 100vh;

    .el-header {
        padding: 0;

    }

    .el-aside {
        overflow-x: hidden;

        &.full_aside_width {
            position: absolute;
            width: 100% !important;
            z-index: 9998;
            min-height: 800px;
        }

        &.mobile_aside_width {
            width: $sidebar-width !important;
        }

        &.collapsed {
            width: 90px !important;
        }

        &.collapsed_mobile {
            width: 0 !important;
        }
    }

    .el-main {
        background-color: $dom-light-gray;
    }
}

</style>
