<template>
    <!--
    Диалог создания нового голосования
    -->
    <el-dialog
        :width="dialogWidth"
        class="new-voting-dialog"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :show-close="false"
        id="new-vote-dialog"
        :class="{dialog_full_display: !$isMobile()}"
        :modal-append-to-body="false">

        <template v-slot:title>
            <el-container style="justify-content: space-between; align-content: center">
                <div class="title">Новое голосование</div>
                <el-link type="primary" :underline="false" style="font-size: 18px" @click="onCancel">
                    <span v-if="$vssWidth > 600">Завершить без сохранения</span>
                    <i class="el-icon--right el-icon-error close-icon"></i>
                </el-link>
            </el-container>
        </template>
        <el-tabs tab-position="left" class="tab-pane-wrapper" :value="tab">

            <!--            Шаг 1 - выбор объекта недвижимости -->
            <el-tab-pane class="tab-pane" name="1">
                <new-vote-tab-label slot="label" number="1" title="Выбор объекта недвижимости"/>
                <el-container class="p-4">
                    <dom-dropdown left-icon="el-icon-location" v-model="realty" :items="properties" text-key="address" value-key="property_id"/>
                </el-container>
                <dom-button-accept @click="changeTabFrom1to2" :disabled="!realty.address" label="Сохранить и продолжить" style="margin-top: 10px"/>
            </el-tab-pane>


            <!--            Шаг 2 (1.a) - заполнение данных администратора, если его нет -->
            <el-tab-pane class="tab-pane" name="2" v-if="shouldEnterUserData && completedStep > 1 ">
                <new-vote-tab-label slot="label" number="1.a" title="Данные администратора голосования"/>

                <!-- Физ лицо-->
                <template v-if="me.jurForm ==='fl'">
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.name" placeholder="ФИО" icon-left="fas el-icon-fa-user" readonly/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.passportNo" placeholder="Серия и номер паспорта" icon-left="fas el-icon-fa-address-card"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.address" placeholder="Место и/или фактический адрес" icon-left="fas el-icon-fa-map-marker-alt"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.phoneNumber" placeholder="Номер телефона" icon-left="fas el-icon-fa-phone-square-alt"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="realty.adminPhoneNumber" placeholder="Контактные телефоны админа ОСС" icon-left="fas el-icon-fa-phone-square-alt"/>
                    </el-row>
                    <dom-textarea v-model="realty.materialOrder" placeholder="Порядок ознакомления с материалами" icon-left="fas el-icon-fa-phone-square-alt" maxlength="512"/>
                    <dom-textarea v-model="realty.decisionOrder" placeholder="Порядок представления письменных решений" icon-left="fas el-icon-fa-phone-square-alt" maxlength="512"/>
                    <!--                    <dom-textarea v-model="realty.announcementOrder" placeholder="Порядок предоставления сообщений о проведении ОСС" icon-left="fas el-icon-fa-phone-square-alt"/>-->
                </template>

                <!-- Юр лицо-->
                <template v-else>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.name" placeholder="Наименование" icon-left="fas el-icon-fa-user"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.orgForm" placeholder="Организационно-правовая форма" icon-left="fas el-icon-fa-suitcase"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.address" placeholder="Место нахождения" icon-left="fas el-icon-fa-map-marker-alt"/>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="me.postAddress" placeholder="Почтовый адрес" icon-left="fas el-icon-fa-map-marker-alt"/>
                    </el-row>
                    <el-row :gutter="10" style="margin: 12px 0">
                        <el-col :span="12">
                            <dom-input v-model="me.internetAddress" placeholder="Веб-сайт" icon-left="fas el-icon-fa-laptop"/>
                        </el-col>
                        <el-col :span="12">
                            <dom-input v-model="me.phoneNumber" placeholder="Номер телефона" icon-left="fas el-icon-fa-phone-square-alt"/>
                        </el-col>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <dom-input v-model="realty.adminPhoneNumber" placeholder="Контактные телефоны админа ОСС" icon-left="fas el-icon-fa-phone-square-alt"/>
                    </el-row>
                    <dom-textarea v-model="realty.materialOrder" placeholder="Порядок ознакомления с материалами" icon-left="fas el-icon-fa-phone-square-alt" maxlength="512"/>
                    <dom-textarea v-model="realty.decisionOrder" placeholder="Порядок представления письменных решений" icon-left="fas el-icon-fa-phone-square-alt" maxlength="512"/>
                    <!--                    <dom-textarea v-model="realty.announcementOrder" placeholder="Порядок предоставления сообщений о проведении ОСС" icon-left="fas el-icon-fa-phone-square-alt"/>-->
                </template>

                <dom-button @click="changeTab(3, '3')"
                            style="margin-top: 20px; height: 55px"
                            left-icon="el-icon-circle-check"
                            right-icon="el-icon-caret-right"
                            label="Сохранить и продолжить"
                />
            </el-tab-pane>


            <!--            Шаг 3(2) - выбор дат голосования -->
            <el-tab-pane class="tab-pane" name="3" v-if="tab >= 3">
                <new-vote-tab-label slot="label" number="2" title="Выбор даты голосования"/>
                <el-container class="is-vertical">
                    <el-row style="margin: 12px 0">
                        <el-col :span="24">
                            <dom-datepicker v-model="dateFrom"
                                            placeholder="Дата начала голосования"
                                            :picker-options="dateFromPickerOptions"
                                            prefix-icon="fas el-icon-fa-hourglass-start"/>
                        </el-col>
                    </el-row>
                    <el-row style="margin: 12px 0">
                        <el-col :span="24">
                            <dom-datepicker v-model="dateTo"
                                            placeholder="Дата окончания голосования"
                                            :disabled="isDateToComputed"
                                            :readonly="isDateToComputed"
                                            :default-value="defaultDate"
                                            :picker-options="dateToPickerOptions"
                                            prefix-icon="fas el-icon-fa-hourglass-end"/>
                        </el-col>
                    </el-row>


                    <dom-button @click="changeTab(4, '4')"
                                style="margin-top: 20px; height: 55px"
                                left-icon="el-icon-circle-check"
                                right-icon="el-icon-caret-right"
                                :disabled="!dateFrom || !dateTo"
                                label="Сохранить и продолжить"
                    />
                </el-container>
            </el-tab-pane>


            <el-tab-pane class="tab-pane" name="4" v-if="completedStep >= 4">
                <new-vote-tab-label slot="label" number="3" title="Повестка и документы"/>
                <el-container class="is-vertical"
                              v-if="questions"
                >
                    <draggable
                        :list="questions"
                        handle='.handle'
                        :dragOptions="dragOptions"
                        :scroll-sensitivity="200"
                        :force-fallback="true"
                    >
                        <transition-group>
                            <el-row type="flex" justify="center" align="center" v-for="(question, idx) in questions" :key="idx" class="question">
                                <el-card class="mb-15" style="width: 100%">
                                    <el-row type="flex" align="middle" justify="end">
                                        <el-col class="handle" style="cursor: move; text-align: end; margin-bottom: 15px; font-size: 18px">
                                            <el-tooltip content="Переместить" placement="top">
                                                <i class="fas el-icon-fa-bars" style="color: #545454"></i>
                                            </el-tooltip>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20" type="flex" align="middle">
                                        <el-col :span="2" class="question__index">
                                            {{ idx + 1 }}
                                        </el-col>
                                        <el-col :span="24">
                                            <el-input type="textarea" class="question__title " v-model="question.text"/>
                                            <i class="el-icon-warning question__title_icon"></i>
                                        </el-col>
                                    </el-row>
                                    <el-row style="display: flex; justify-content: flex-end" class="mt-5">
                                        <el-upload class="question__upload_button"
                                                   :auto-upload="false"
                                                   :show-file-list="false"
                                                   :multiple="true"
                                                   :limit="10485760"
                                                   style="width: 200px;"
                                                   :on-change="(file, fileList) => onAddFile(question, file, fileList)"
                                                   action="">
                                            <i class="fas el-icon-fa-file-upload ml-15"></i>
                                            <span>Загрузить файл</span>
                                        </el-upload>
                                    </el-row>
                                    <el-row v-for="(file, idx) in question.files" class="question__files">
                                        <i class="fas el-icon-fa-file-alt" style="color: #1d9ef9; margin-right: 10px; font-size: 18px; font-weight: 400;"/>
                                        {{ file.name }}
                                        <i class="fas el-icon-fa-times-circle question__files_delete" @click="onRemoveFile(question, idx)"/>
                                    </el-row>
                                    <el-row :span="1" style="margin-top: 20px">
                                        <i class="el-icon-delete question__delete" @click="deleteQuestion(question, idx)"></i>
                                    </el-row>

                                </el-card>

                            </el-row>
                        </transition-group>

                    </draggable>
                    <dom-button
                        style="height: 55px; margin-left: 10px; padding-right: 31px;"
                        left-icon="el-icon-circle-plus-outline"
                        label="Добавить вопрос: из шаблона"
                        @click="isAddQuestionDialogVisible = true"
                    />
                    <dom-button
                        style="margin-top: 10px; height: 55px"
                        left-icon="el-icon-circle-plus-outline"
                        label="Добавить вопрос: свой вариант"
                        @click="isAddOwnQuestionDialogVisible = true"
                    />
                    <dom-button @click="changeTab(5, '5')"
                                style="margin-top: 20px; height: 55px"
                                left-icon="el-icon-circle-check"
                                right-icon="el-icon-caret-right"
                                :disabled="!questions.length"
                                label="Сохранить и продолжить"
                    />

                    <new-vote-add-question-dialog
                        v-model="isAddQuestionDialogVisible"/>

                    <new-vote-add-own-question-dialog
                        v-model="isAddOwnQuestionDialogVisible"
                        :questions="questions"/>

                </el-container>
            </el-tab-pane>


            <el-tab-pane class="tab-pane" name="5" v-if="completedStep >= 5">
                <new-vote-tab-label slot="label" number="4" title="Заголовок голосования"/>
                <el-container class="is-vertical">
                    <dom-textarea
                        v-model="title"
                        icon-left="el-icon-info"
                        placeholder="Укажите краткий заголовок голосования до 256 знаков"
                    />
                    <dom-button @click="changeTab(6, '6')"
                                style="margin-top: 20px; height: 55px"
                                left-icon="el-icon-circle-check"
                                right-icon="el-icon-caret-right"
                                :disabled="!title"
                                label="Сохранить и продолжить"
                    />
                </el-container>
            </el-tab-pane>


            <el-tab-pane class="tab-pane" name="6" v-if="completedStep >= 6">
                <new-vote-tab-label slot="label" number="5" title="Проверка и инициация"/>

                <el-row style="margin: 12px 0" :gutter="10">
                    <el-col :span="14" :xs="24">
                        <dom-input style="margin-top: 14px" :value="realty.address" icon-left="fas el-icon-fa-map-marker-alt"/>
                    </el-col>
                    <el-col :span="10" :xs="24">
                        <dom-input style="margin-top: 14px" :value="dateFromToRo" icon-left="el-icon-date"/>
                    </el-col>

                </el-row>
                <el-row style="margin: 12px 0" :gutter="12">
                    <el-col>
                        <dom-input :value="title" placeholder="Заголовок голосования" icon-left="el-icon-info"/>
                    </el-col>
                </el-row>

                <dom-button @click="onFinish"
                            style="margin-top: 20px; height: 55px"
                            left-icon="el-icon-circle-check"
                            right-icon="el-icon-caret-right"
                            label="Отправить на утверждение"
                />
            </el-tab-pane>


        </el-tabs>

        <el-dialog
            append-to-body
            width="60%"
            class="warning-dialog"
            title="Внимание!"
            :visible.sync="adminConfinrmDialogVisible"
            center
        >
            <div style="padding: 10px">
                <div style="padding-bottom: 25px; font-size: 18px;">Вы будете назначены Администратором ОСС</div>
                <div>Вам необходимо ввести дополнительные данные. Эти данные будут использоваться в отчетных документах ОСС.</div>
            </div>

            <span slot="footer" class="dialog-footer">
                   <el-button type="danger" plain @click="adminConfinrmDialogVisible = false">Не соглашаюсь</el-button>
                   <el-button type="success" @click="adminConfinrmDialogVisible = false">Подтвердить</el-button>
                  </span>
        </el-dialog>

    </el-dialog>

</template>

<script>

import Toggleable from "@/views/mixins/Toggleable";
import NewVoteAddQuestionDialog from "@/views/dialogs/NewVoteAddQuestionDialog";
import NewVoteAddOwnQuestionDialog from "@/views/dialogs/NewVoteAddOwnQuestionDialog";
import DomDropdown from "@/views/elements/DomDropdown";
import NewVoteTabLabel from "@/views/components/NewVoteTabLabel";
import DomDatepicker from "@/views/elements/DomDatepicker";
import DomButton from "@/views/elements/DomButton";
import DomDropdownComplex from "@/views/elements/DomDropdownComplex";
import DomTextarea from "@/views/elements/DomTextarea";
import DomHuman from "@/views/elements/DomHuman";
import DomInput from "@/views/elements/DomInput";
import {mapActions, mapGetters} from "vuex";
import draggable from 'vuedraggable'

import api from '../../api'
import moment from "moment";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import {mapFields} from "vuex-map-fields";
import VueScreenSize from "vue-screen-size";

//Дата начала - Не может быть раньше,  чем текущая дата плюс 14 дней
const MIN_DAYS_FROM_CURRENT = 14

//Дата окончания должна  отстоять от даты начала не менее чем на 7 дней, но не более 60 дней.
const MIN_DAYS_FROM_START = 7
const MAX_DAYS_FROM_START = 60

export default {

    name: "NewVoteDialog",
    components: {
        DomButtonAccept,
        draggable,
        DomInput, NewVoteAddOwnQuestionDialog, DomHuman, DomTextarea, DomDropdownComplex, DomButton, DomDatepicker, NewVoteTabLabel, DomDropdown, NewVoteAddQuestionDialog
    },
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],

    data: () => ({
        tab: '1',
        completedStep: 1,
        adminConfinrmDialogVisible: false,
        isAddQuestionDialogVisible: false,
        isAddOwnQuestionDialogVisible: false,

        adminType: 'fl',

        realty: {},
        dateFrom: null,
        dateTo: null,
        title: null,
        admin_oss: null,
    }),
    asyncComputed: {
        properties() {
            return this.getMy();
        }
    },

    computed: {
        ...mapGetters("questions", ["questions"]),
        ...mapFields("user", ["me"]),
        dragOptions() {
            return {
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                direction: "vertical"
            };
        },
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        dateFromToRo() {
            return moment(this.dateFrom).format('DD.MM.YYYY') + ' - ' + moment(this.dateTo).format('DD.MM.YYYY')
        },

        // Иногда дата окончания голосования рассчитывается автоматически
        isDateToComputed() {
            return !!this.realty && !!this.realty.votingDuration
        },

        // Второй шаг отображается только если у недвижимости нет администратора
        shouldEnterUserData() {
            return !this.realty.admin
        },

        dateFromPickerOptions() {
            return {
                disabledDate(date) {
                    const dateMin = moment().add(MIN_DAYS_FROM_CURRENT, 'days').toDate()
                    return date <= dateMin
                }
            }
        },

        dateToPickerOptions() {
            const self = this
            return {
                disabledDate(date) {
                    const dateMin = moment(self.dateFrom).add(MIN_DAYS_FROM_START, 'days').toDate();
                    const dateMax = moment(self.dateFrom).add(MAX_DAYS_FROM_START, 'days').toDate();
                    return date <= dateMin || date >= dateMax
                }
            }
        },
        defaultDate() {
            const self = this
            return moment(self.dateFrom).add(MIN_DAYS_FROM_START, 'days').toDate();
        }

    },

    watch: {
        properties: {
            handler(properties) {
                if (properties && properties.length && _.isEmpty(this.realty)) {
                    this.realty = properties[0]
                }
            },
            immediate: true
        },

        tab(tab) {
            switch (tab) {
                case '1':
                    break;
                case '3':
                    this.dateFrom = moment().add(MIN_DAYS_FROM_CURRENT, 'days').toDate()
                    break;
            }
        },
        realty() {
            this.tab = '1';
            this.completedStep = 1;
        },
        dateFrom() {
            if (this.dateFrom && this.isDateToComputed) {
                this.dateTo = moment(this.dateFrom).add(this.realty.votingDuration, 'days').toDate();
            }
        },
    },

    mounted() {
        this.getAll();
        this.get_me();
    },

    methods: {
        ...mapActions("property", ["getAll", "getMy"]),
        ...mapActions("voting", ["createVoting"]),
        ...mapActions("user", ["get_me"]),

        deleteQuestion(data, idx) {
            const arr = this.questions || [];

            this.$confirm('Вы действительно хотите удалить элемент?', 'Внимание', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                arr.splice(idx, 1);

                this.$message({
                    type: 'success',
                    message: 'Успешно удалено.'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Отменено'
                });
            });

        },

        changeTab(completedStep, nextTab) {
            this.completedStep = completedStep
            this.tab = nextTab
            let elem = document.getElementById('new-vote-dialog')
            elem.scrollTo(0, 0);
        },

        // Обработка пункта 1.a
        changeTabFrom1to2() {
            // 1, '2'
            if (this.realty.admin) {
                this.admin_oss = this.realty.admin
                this.completedStep = 2
                this.tab = '3'

            } else if (this.me) {

                let question;
                if (this.me.jurForm === 'fl')
                    question = this.confirmAdminExistsPerson()
                else // Юр лицо
                    question = this.confirmAdminExistsOrg()

                question.then(() => {
                    this.admin_oss = this.me;
                    this.tab = '2'
                    this.completedStep = 2
                }).catch(() => {
                    this.isActive = false;
                })
            }
        },

        confirmAdminExistsPerson() {
            return this.$confirm(
                `
                    <div style="padding: 10px">
                        <div style="padding-bottom: 25px; font-size: 18px;">Вы будете назначены Администратором ОСС</div>
                        <div>Ваши персональные данные (номер и серия паспорта, фактический адрес проживания, телефон, email) будут направлены собственникам помещений в сообщении о проведении общего собрания</div>
                    </div>`,
                "Внимание!",
                {dangerouslyUseHTMLString: true}
            )
        },

        confirmAdminNewPerson() {
            return this.$confirm(
                `
                    <div style="padding: 10px">
                        <div style="padding-bottom: 25px; font-size: 18px;">Вы будете назначены Администратором ОСС</div>
                        <div>Вам необходимо ввести дополнительные данные.  Эти данные будут использоваться в отчетных документах ОСС.</div>
                    </div>`,
                "Внимание!",
                {dangerouslyUseHTMLString: true}
            )
        },

        confirmAdminExistsOrg() {
            return this.$confirm(
                `
                    <div style="padding: 10px">
                        <div style="padding-bottom: 25px; font-size: 18px;">Вы будете назначены Администратором ОСС</div>
                        <div>Ваши данные будут направлены собственникам помещений в сообщении о проведении общего собрания. </div>
                    </div>`,
                "Внимание!",
                {dangerouslyUseHTMLString: true}
            )
        },

        confirmAdminNewOrg() {
            return this.$confirm(
                `
                    <div style="padding: 10px">
                        <div style="padding-bottom: 25px; font-size: 18px;">Вы будете назначены Администратором ОСС</div>
                        <div>Вам необходимо ввести дополнительные данные. Эти данные будут использоваться в отчетных документах ОСС.</div>
                    </div>`,
                "Внимание!",
                {dangerouslyUseHTMLString: true}
            )
        },

        onCancel() {
            this.completedStep = 1;
            this.tab = '1';
            this.title = null;
            this.realty = {};
            this.dateFrom = null;
            this.dateTo = null;
            this.isActive = false;
            this.$store.dispatch('questions/CLEAR_QUESTIONS');
        },

        onFinish() {
            const files = [];

            const questions = this.questions.map((d, idx) => ({
                file_ids: _.map(d.files, d => {
                    const name = "" + files.length;
                    files.push(d.raw);
                    return name;
                }),
                algorithmCd: d.algorithm,
                title: d.text,
                ordr: idx,
            }))

            const data = {
                voting_id: null,
                property: this.realty,
                title: this.title,
                questions: questions,
                start_dttm: moment(this.dateFrom).hour(10).minute(0).second(0).millisecond(0).toISOString(true),
                end_dttm: moment(this.dateTo).hour(9).minute(59).second(59).millisecond(0).toISOString(true),
                voting_status: null,
                admin: this.admin_oss,

            }

            api.voting.post(data, files)
                .then(() => this.onCancel())
        },

        onAddFile(question, file) {
            question.files.push(file);

        },

        onRemoveFile(question, idx) {
            question.files.splice(idx, 1)
        },
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";


.title {
    font-size: 22px;
    font-weight: bold;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.close-icon {
    font-size: 24px;
}

.el-dialog__wrapper.warning-dialog {
    ::v-deep .el-dialog__header {
        background: $dom-light-gray;
        height: 34px;
    }

    ::v-deep .el-dialog__title {
        font-size: 26px;
    }
}

.question {
    ::v-deep .question__index {
        width: 30px;
        height: 30px;
        line-height: 30px;
        color: $dom-white;
        background: $dom-green;
        font-size: 18px;
        border-radius: 50%;
        margin: 0 !important;
        padding: 0 !important;
        text-align: center;
    }

    .question__files {
        margin: 15px 0 0 0;
        padding: 0 0 0 35px;
        min-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: $dom-light-blue;
    }

    .question__files:last-child {
        margin-bottom: 0;
    }

    .question__files_delete {
        color: $dom-gray;
        font-size: 16px;
        margin-left: 5px;
    }

    .question__files_delete:hover {
        cursor: pointer;
    }


    .question__delete {
        color: $dom-red;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $dom-red;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
    }

    .question__delete:hover {
        cursor: pointer;
    }

    .question__title {
        background: $dom-light-gray;
        border-radius: 3px;
        padding: 7px;
        font-size: 16px;

        &.el-textarea {
            padding: 0;

            ::v-deep textarea {
                padding-left: 30px;
            }

            & + i {
                position: absolute;
                left: 45px;
                top: 7px;
            }
        }
    }

    .question__upload_button {
        padding: 5px 0;
        vertical-align: center;
        text-align: center;
        color: $dom-light-blue;
        border-radius: 5px;
        border: 2px solid $dom-light-blue;

        ::v-deep i {
            font-size: 18px;
        }

        ::v-deep span {
            margin: 0 10px;
            line-height: 20px;
            font-size: 14px;
        }


    }

    .question__title_icon {
        color: $dom-light-blue;
        padding-right: 10px;
        font-size: 20px;
    }
}

@media (max-width: 700px) {
    .new-voting-dialog {
        ::v-deep .el-dialog {
            margin-top: 0 !important;
            height: 100% !important;
        }
    }
}

.dialog_full_display {
    max-height: 70vh;
    margin-top: 35px;
    width: 100%;
}

.new-voting-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }
}

.tab-pane {
    background-color: $dom-light-gray;
    height: 100%;
}

.tab-pane-wrapper {
    background-color: $dom-light-gray;
    height: 100%;

    ::v-deep .el-tabs__item {
        background-color: $dom-light-gray;
        padding: 0;
        height: 80px;
        //width: 250px;
    }

    ::v-deep .el-tabs__content {
        max-height: 100%;
        padding: 20px;
    }
}


</style>
