<template>
    <!--
    Компонент ввода имени пользователя
    -->

    <el-input class="dom-human" v-model="valueInternal" :placeholder="placeholder">
        <i v-if="iconLeft" slot="prefix" :class="iconLeft" class="icon-left"/>
    </el-input>


</template>

<script>

import ValueInternal from '@/views/mixins/ValueInternal'

export default {
    name: "dom-human",

    mixins: [ValueInternal],

    props: {
        placeholder: {
            type: String
        },

        iconLeft: {
            type: String
        }
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-human {

    background-color: $dom-white;

    .icon-left {
        color: $dom-light-blue;
        margin-top: 13px;
        margin-left: 5px;
    }

}


</style>
