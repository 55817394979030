<template>
    <!--
    Диалог добавления вопросов вручную
    -->
    <el-dialog
        :width="dialogWidth"
        class="add-question-dialog"
        :visible.sync="isActive"
        append-to-body
        center
        :show-close="false"
    >
        <template v-slot:title>
            <el-row type="flex" justify="space-between" align="middle">
                <el-col>
                    <div style="font-weight: 600;">Добавление вопроса для проведения голосования</div>
                </el-col>
                <el-col>
                    <div>
                        <dom-button
                            style="font-weight: 600; padding-right: 36px;"
                            label="Добавить вопрос"
                            :disabled="!formIsReady"
                            @click="setQuestion"
                        />
                    </div>
                </el-col>
                <el-col :span="1">
                    <div>
                        <el-link type="primary" :underline="false" style="font-size: 24px" @click="onCancel">
                            <i class="el-icon--right el-icon-error close-icon"></i>
                        </el-link>
                    </div>
                </el-col>
            </el-row>
        </template>
<!--        <el-row  :gutter="20" align="center" justify="start" type="flex" style="flex-wrap: wrap">-->
<!--            <el-col :span="24">-->
<!--                <dom-dropdown v-model="algorithm" :items="algorithms || []" value-key="cd" text-key="nm" use-key/>-->
<!--            </el-col>-->
<!--        </el-row>-->
        <el-row  :gutter="20" align="center" justify="start" type="flex" style="flex-wrap: wrap">
            <el-col :span="24">
                <dom-textarea
                    v-model="question"
                    maxlength="4096"
                    icon-left="el-icon-info"
                    placeholder="Введите текст вашего вопроса"
                />
            </el-col>

        </el-row>
    </el-dialog>
</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import {mapActions} from "vuex";
import DomDropdown from "@/views/elements/DomDropdown";
import VueScreenSize from "vue-screen-size";

export default {
    name: "NewVoteAddQuestionDialog",
    props: {
        questions: {}
    },
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],
    components: {DomDropdown, DomButton, DomTextarea},
    data() {
        return {
            question: '',
            algorithm: null,
        }
    },
    asyncComputed: {
        algorithms() {
            return this.getAlgorithms();
        }
    },
    computed: {
        formIsReady() {
            return !!this.question
        },
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },
    },
    methods: {
        ...mapActions("questions", ["SET_OWN_QUESTION"]),
        ...mapActions("meta", ["getAlgorithms"]),

        setQuestion() {
            let data = {
                id: null,
                text: this.question,
                algorithm: null,
                files: [],
            }
            this.SET_OWN_QUESTION(data);
            this.isActive = false;
            this.question = '';
            this.algorithm = null;
        },
        onCancel() {
            this.isActive = false;
            this.question = '';
            this.algorithm = null;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.add-question-dialog {
    ::v-deep .el-dialog {
        min-height: 70vh;
        height: fit-content;
        background: $dom-light-gray;
    }

    ::v-deep .el-dialog__header {
        background: $dom-white;
    }

    ::v-deep .el-checkbox__inner {
        border: 1px solid $dom-gray;
        margin-bottom: 2px;
        width: 16px;
        height: 16px
    }

    ::v-deep .el-checkbox__label {
        font-size: 16px;
    }

    .question__header {
        color: $dom-light-blue;
        font-size: 18px;
    }
}

::v-deep .el-checkbox.is-checked {
    .el-checkbox__label {
        color: $dom-dark-gray;
    }
}

::v-deep .highlight {
    background-color: yellow !important;
}

</style>
