import { render, staticRenderFns } from "./DomSearch.vue?vue&type=template&id=12974315&scoped=true&"
import script from "./DomSearch.vue?vue&type=script&lang=js&"
export * from "./DomSearch.vue?vue&type=script&lang=js&"
import style0 from "./DomSearch.vue?vue&type=style&index=0&id=12974315&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12974315",
  null
  
)

export default component.exports