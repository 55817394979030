<template>
    <!--
    Компонент заголовка таба в мастере добавления (например, голосования)
    -->

    <el-container class="new-vote-tab-label">
        <div class="tab-label-number">{{ number }}</div>
        <div class="tab-label-title">{{ title }}</div>
    </el-container>

</template>

<script>
export default {

  props: {
    number: {
      required: true,
    },
    title: {
      required: true,
    },
  }

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.el-container {
  height: 100%;
  align-items: center;
  padding: 0 16px;
  background-color: $dom-white;
}

.tab-label-number {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 34px;
  border-radius: 50%;
  padding: 5px;

  display: flex;
  justify-content: center;
  flex-grow: 0;

  border: solid 2px $dom-light-gray;
  color: $dom-black;
  background: $dom-light-gray;
}

.tab-label-title {
  height: 40px;
  width: 120px;
  line-height: initial;
  text-align: start;
  margin-left: 10px;

  white-space: normal;
  word-break: normal;

  display: flex;
  flex-grow: 1;
  align-items: center;

  color: $dom-black;
  font-size: 90%;
}


.is-active .el-container {
  background-color: $dom-light-gray;

  .tab-label-number {
    border: solid 2px $dom-light-blue;
    color: $dom-black;
    background: $dom-white;
  }

  .tab-label-title {
    color: $dom-black;
    font-weight: bold;
  }
}


</style>