<template>
    <!--
    Диалог добавления вопросов из шаблонов
    -->
    <el-dialog
        :width="dialogWidth"
        class="add-question-dialog"
        :visible.sync="isActive"
        append-to-body
        center
        :show-close="false">

        <template v-slot:title>
            <el-row type="flex" justify="space-between" align="middle">
                <el-col>
                    <div style="font-weight: 600;">Шаблоны вопросов для проведения голосования</div>
                </el-col>
                <el-col v-if="$vssWidth > 700">
                    <dom-search v-model="query" placeholder="Поиск по вопросам"/>
                </el-col>
                <el-col>
                    <dom-button
                        style="font-weight: 600; padding-right: 36px;"
                        label="Добавить вопросы"
                        :disabled="!checkedQuestions.length"
                        @click="setQuestions"
                    />
                </el-col>
                <el-col :span="1">
                    <el-link type="primary" :underline="false" style="font-size: 24px" @click="onCancel">
                        <i class="el-icon--right el-icon-error close-icon"></i>
                    </el-link>
                </el-col>
            </el-row>
        </template>

        <el-row :gutter="20" align="center" justify="start" type="flex" style="flex-wrap: wrap">
            <el-col :span="12" :xs="24" v-for="card in templateQuestions">
                <el-card class="box-card mb-15">
                    <div slot="header" class="clearfix" style="display: flex; justify-content: space-between">
                        <span class="question__header">{{ card.title }}</span>
                        <el-checkbox v-model="card.checked" :indeterminate="card.indeterminate" @change="onCheckAllChange($event, card)">Выбрать все</el-checkbox>
                    </div>
                    <el-checkbox class="question-checkbox" v-for="question in card.data" v-model="question.checked" @change="recalcIndeterminate(card)" :label="question" style="margin-bottom: 10px">
                        <span v-html="highlight(question.text)"></span>
                    </el-checkbox>
                </el-card>
            </el-col>

        </el-row>
    </el-dialog>
</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import DomSearch from "@/views/elements/DomSearch";
import DomButton from "@/views/elements/DomButton";
import {mapActions, mapGetters} from "vuex";
import VueScreenSize from "vue-screen-size";

export default {
    name: "NewVoteAddQuestionDialog",
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],
    components: {DomSearch, DomButton},
    data() {
        return {
            query: ''
        }
    },
    computed: {
        ...mapGetters("questions", ["templateQuestions"]),
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        checkedQuestions() {
            return _(this.templateQuestions).map('data').flatten().filter('checked').value()
        },
    },
    created() {
        this.LOAD_TEMPLATES();
        this.CLEAR_QUESTIONS();
    },

    methods: {
        ...mapActions("questions", ["LOAD_TEMPLATES", "SET_QUESTIONS", "CLEAR_QUESTIONS"]),

        __onToggleOn() {
            this.templateQuestions.forEach(d => {
                d.indeterminate = d.checked = false
                d.data.forEach(v => v.checked = false)
            })
            return _(this.templateQuestions).map('data').flatten().forEach(d => d.checked = false)
        },

        highlight(d) {
            const query = this.query !== null ? this.query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') : "";
            const r = new RegExp(query, "gi");
            return query !== null && query.length ? d.replace(r, "<span class='highlight'>$&</span>") : d
        },
        onCheckAllChange(value, card) {
            card.data.forEach(d => d.checked = value)
            card.indeterminate = false
        },
        recalcIndeterminate(card) {
            const cnt = card.data.length;
            const chk = _.filter(card.data, 'checked').length;
            if (chk === 0) {
                card.indeterminate = false;
                card.checked = false;
            } else if (cnt !== chk) {
                card.indeterminate = true;
                card.checked = false;
            } else {
                card.indeterminate = false;
                card.checked = true;
            }
        },
        setQuestions() {
            this.SET_QUESTIONS(this.checkedQuestions);
            this.isActive = false;
        },
        onCancel() {
            this.isActive = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

@media (max-width: 700px) {
    .add-question-dialog {
        ::v-deep .el-dialog {
            min-height: 80vh;
            margin-top: 0 !important;
            height: 100% !important;
            background: $dom-light-gray;
        }
    }
}

.add-question-dialog {
    ::v-deep .el-dialog {
        min-height: 70vh;
        height: fit-content;
        background: $dom-light-gray;
    }

    ::v-deep .el-dialog__header {
        background: $dom-white;
    }

    ::v-deep .el-dialog__body {
        overflow: auto;
        position: absolute;
        top: 72px;
        bottom: 0;
    }

    ::v-deep .el-checkbox__inner {
        border: 1px solid $dom-gray;
        margin-bottom: 2px;
        width: 16px;
        height: 16px
    }

    ::v-deep .el-checkbox__label {
        font-size: 16px;
    }

    .question__header {
        color: $dom-light-blue;
        font-size: 18px;
    }
}

::v-deep .el-checkbox.is-checked {
    .el-checkbox__label {
        color: $dom-dark-gray;
    }
}

::v-deep .highlight {
    background-color: yellow !important;
}

.question-checkbox {
    padding-bottom: 5px;

    ::v-deep .el-checkbox__input {
        vertical-align: top;
    }

    ::v-deep .el-checkbox__label {
        white-space: normal;
        word-break: break-word;
    }
}

</style>
