<template>
    <!--
    Компонент бокового меню
    -->

    <el-menu :default-active="active" :collapse="!isMenuOpenedGetter">

        <router-link :to="{name: 'votes'}">
            <el-menu-item index="votes">
                <i :class="`fas el-icon-fa-vote-yea el-icon-fa-fw menu-icon`"/>
                <span class="menu-text">Голосования</span>
            </el-menu-item>
        </router-link>

        <router-link :to="{name: 'properties'}">
            <el-menu-item index="properties">
                <i :class="`fas el-icon-fa-building el-icon-fa-fw menu-icon`"/>
                <span class="menu-text">Недвижимость</span>
            </el-menu-item>
        </router-link>

        <router-link :to="{name: 'users'}">
            <el-menu-item index="users" v-if="$is_admin()">
                <i :class="`fas el-icon-fa-users el-icon-fa-fw menu-icon`"/>
                <span class="menu-text">Пользователи</span>
            </el-menu-item>
        </router-link>

        <router-link :to="{}">
            <el-menu-item index="-1" class="menu-button" @click="$emit('new-vote')">
                <div class="menu-button-frame">
                    <i :class="`fas el-icon-fa-plus menu-icon`"/>
                    <span class="menu-text-iphone" v-if="isIphone">Новое голосование</span>
                    <span class="menu-text" v-else>Новое голосование</span>
                </div>
            </el-menu-item>
        </router-link>

        <router-link :to="{name: 'profile'}">
            <el-menu-item index="profile">
                <i :class="`fas el-icon-fa-user-circle el-icon-fa-fw menu-icon`"/>
                <span class="menu-text">Личный кабинет</span>
            </el-menu-item>
        </router-link>

        <router-link :to="{name: 'notifications'}">
            <el-menu-item index="notifications">
                <i :class="`fas el-icon-fa-envelope-open-text el-icon-fa-fw menu-icon`"/>
                <span class="menu-text">Уведомления</span>
                <span class="counter">{{ notifications.length }}</span>
            </el-menu-item>
        </router-link>

        <router-link :to="{}">
            <el-menu-item index="-2" @click="logout()">
                <i :class="`fas el-icon-fa-sign-out-alt el-icon-fa-fw menu-icon`"/>
                <span class="menu-text">Выход</span>
            </el-menu-item>
        </router-link>

    </el-menu>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {

    data: () => ({
        active: "1",
    }),

    computed: {
        ...mapFields("notifications", ["notifications"]),
        ...mapGetters("menu", ["isMenuOpenedGetter"]),
        isIphone(){
            if (/iPhone/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },

    created() {
        this.get_notifications();
    },

    mounted() {
        this.active = this.$router.currentRoute.name
    },

    methods: {
        ...mapActions("login", ["clear_code"]),
        ...mapActions("notifications", ["get_notifications"]),

        logout() {
            this.clear_code().then(() => location.reload())
        },
    },


}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

a {
    text-decoration: none;
}

.el-menu {

    /*width: $sidebar-width*/
    min-width: 90px;
    background-color: $dom-blue;

    box-sizing: border-box;
    padding: 30px 0;
    height: 100%;

    .el-menu-item {
        padding: 20px;
        height: auto;

        .menu-icon {
            color: $dom-blue;
            background-color: $dom-white;
            padding: 10px;
            border-radius: 50%;
            line-height: 30px;
            width: 30px;
            font-size: 19pt;
        }

        .menu-text {
            color: $dom-white;
            font-size: 1.3em !important;
            margin-left: 20px;
        }

        .menu-text-iphone {
            color: $dom-white;
            font-size: 1.8em !important;
            margin-left: 20px;
        }

        .counter {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: $dom-green;
            color: white;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }

        &:hover, &:focus {
            background: $dom-blue;
        }

        &.is-active {
            background: $dom-light-blue;
        }

    }

    .el-menu-item.menu-button {

        position: relative;
        left: -2px;

        .menu-button-frame {
            border: $dom-white solid 2px;
            padding: 0 2px;
            border-radius: 50px;
            height: 51px;
            width: 47px;

            * {
                position: relative;
                top: -4px;
            }

            .menu-icon {
                color: $dom-white;
                background-color: $dom-green;
                border: $dom-white solid 7px;
                border-radius: 50%;
                padding: 8px;
                line-height: 17px;
                width: 17px;
                font-size: 16pt;
                font-weight: bold;
            }

        }

    }

}

</style>
